import React from "react"
import { Pagination, PaginationItem } from "@mui/material"
import { ResponsiveContext } from "../components/context"
import { Link } from "./link"
import styled from "styled-components"

const Wrapper = styled.div`
  margin-top: 16px;
`

const PaginationContainer = styled(Pagination)`
  ul {
    justify-content: center;
  }
`

export const BasicPagination = () => {
  const { page, totalPage } = React.useContext(ResponsiveContext)

  return (
    <Wrapper>
      <PaginationContainer
        size="large"
        siblingCount={0}
        count={totalPage}
        page={page}
        renderItem={item => (
          <PaginationItem
            {...item}
            component={Link}
            to={"/" + (item.page === 1 ? "" : item.page)}
          />
        )}
      />
    </Wrapper>
  )
}
