import React from "react"

const loadButton = () => {
  window.LineIt && window.LineIt.loadButton()
}

const loadButtons = () => {
  const script = document.createElement("script")
  script.src =
    "https://d.line-scdn.net/r/web/social-plugin/js/thirdparty/loader.min.js"
  script.type = "text/javascript"
  script.async = true
  script.defer = true
  script.onerror = function (error) {
    throw error
  }
  script.onload = function () {
    loadButton()
  }
  document.body.appendChild(script)
}
export class Line extends React.Component {
  componentDidMount() {
    this.componentDidUpdate()
  }
  componentDidUpdate() {
    loadButtons()
  }
  render() {
    const { style, className, url } = this.props

    return (
      <div
        style={Object.assign({}, { display: "none" }, style)}
        className={"line-it-button" + (className ? "" : " " + className)}
        data-lang="zh-TW"
        data-type={"share-d"}
        data-url={url}
      />
    )
  }
}
