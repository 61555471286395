import styled from "styled-components"
import InputBase from "@mui/material/InputBase"

export const PrimaryNavigation = styled.div`
  @media only screen and (max-width: 950px) {
    display: none;
  }

  @media only screen and (max-width: 1050px) {
    font-size: 12px;
  }
  margin: 0 auto;
  display: flex;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  align-items: center;
  justify-content: space-around;

  ul {
    margin: 0px;
  }

  ul li {
    list-style: none;
    margin: 0 auto;
    display: inline-block;
    padding: 0 20px;
    position: relative;
    text-decoration: none;
    text-align: center;
  }

  li a {
    color: black;
  }

  li a:hover {
    color: #999;
  }

  li:hover {
    cursor: pointer;
  }

  ul li ul {
    visibility: hidden;
    opacity: 0;
    display: none;
    position: absolute;
    padding-left: 0;
    left: 0;
    background: white;
    margin: 0px;
    min-width: 120px;
    box-shadow: none;
  }

  ul li:hover > ul {
    visibility: visible;
    opacity: 1;
    display: block;
    min-width: 120px;
    text-align: left;
    padding-top: 10px;
    padding-right: 40px;
    box-shadow: 0px 3px 5px -1px #ccc;
    z-index: 9;
  }

  ul li ul li {
    clear: both;
    width: 100%;
    text-align: left;
    border-style: none;
    margin: 5px 0;
  }

  ul li ul li a {
    margin: 0px;
    display: block;
    width: 100%;
    height: 100%;
    color: #999;
    height: 30px;
    line-height: 30px;
  }
  ul li ul li:hover {
    background-color: #f7f2ec;
    box-shadow: none;
  }

  ul li ul li a:hover {
    color: black;
  }
`

export const Search = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const SearchInputBox = styled(InputBase)`
  font-size: 18px !important;
  font-family: "Amiri";
  width: 70%;
  input {
    border-bottom: 1px solid grey;
    width: ${props => (props.active ? "100%" : "0%")};
    transition: width 0.3s, padding 0.3s;
  }
`
