import styled from "styled-components"
import { Grid, Divider } from "@mui/material"

export const DividerTop = styled(Divider)`
  margin-bottom: 30px !important;
`

export const DividerBottom = styled(Divider)`
  margin-top: 30px !important;
`

export const TitleWrapper = styled.div`
  margin: 50px auto 0px;
  text-align: center;
  @media only screen and (max-width: 960px) and (min-width: 767px) {
    max-width: 726px;
  }
  @media only screen and (max-width: 1400px) {
    max-width: 1200px;
  }
`
export const TagSpan = styled.span`
  font-size: 13px;
  color: #b5b5b5;
  font-style: italic;
  font-weight: 400;
  letter-spacing: 4px;
  text-transform: uppercase;
  display: block;
  margin-bottom: 6px;
`

export const TagH1 = styled.h1`
  font-size: 28px;
  font-weight: 400;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #000;
  font-family: Overpass, sans-serif;
`

export const Wrapper = styled.div`
  margin: 10px auto 50px;
  max-width: 1100px;
  padding: 0 20px;

  @media only screen and (min-width: 960px) {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
`

export const Left = styled.div`
  @media only screen and (min-width: 960px) {
    flex: 3;
  }
`

export const PostsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`

export const AsideWrapper = styled(Grid)`
  padding: 0 20px;
  @media only screen and (min-width: 960px) {
    flex: 1;
  }
`

export const Posts = styled.div`
  width: 45%;
  margin: 15px;
  justify-content: center;
  @media only screen and (max-width: 768px) {
    width: 75%;
    margin: 0px;
  }

  @media only screen and (max-width: 549px) {
    width: 100%;
  }
`
