import styled from "styled-components"

export const Paragraph = styled.div`
  font-family: "Overpass", sans-serif;
  font-size: 13px;
  color: #222222;
  line-height: 1.65;
  font-size: 12.5pt;
  font-weight: 400;
  margin-top: 30px;
`
