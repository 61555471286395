import React from "react"
import styled from "styled-components"

const Container = styled.div`
  border-left: 3px solid #dea08c;
  padding-left: 13px;
  font-family: "Overpass", sans-serif;
  line-height: 1.5;
  font-size: 15px;
`
export const Address = ({ link, address, location, price }) => {
  return (
    <Container>
      <strong>{location}</strong>
      <br />
      <span>
        <b>地址</b>: {address}
      </span>
      <br />
      <span>
        <b>網站：</b>
        <a rel="noreferrer" aria-label={link} href={link} target="_blank">
          {link}
        </a>
      </span>
      <br />
      <span>{price !== "" ? "費用：" + price : ""}</span>
    </Container>
  )
}
