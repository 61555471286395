export const getFormatDate = date => {
  const year = date.getFullYear()
  const prefix = date.getMonth() < 9 ? "0" : ""
  const month = prefix + (date.getMonth() + 1)
  const day = date.getDate()
  return { year, month, day }
}

export const getYearsTillToday = () => {
  return new Date().getFullYear() - 2018 - 1
}

export const tagMapper = tag => {
  switch (tag) {
    case "tips":
      return "辦事打怪篇"
    case "foodie":
      return "美食選輯"
    case "jobs":
      return "工作事件簿"
    case "travel":
      return "旅行散文"
    case "study":
      return "遊學日記"
    case "living":
      return "租屋私房事"
    default:
      return "生活故事書"
  }
}

export const copyToClipboard = textToCopy => {
  let textArea

  const isOS = () => {
    return navigator.userAgent.match(/ipad|iphone/i)
  }

  const createTextArea = text => {
    textArea = document.createElement("textArea")
    textArea.readOnly = true
    textArea.contentEditable = true
    textArea.value = text
    document.body.appendChild(textArea)
  }

  const selectText = () => {
    let range, selection

    if (isOS()) {
      range = document.createRange()
      range.selectNodeContents(textArea)
      selection = window.getSelection()
      selection.removeAllRanges()
      selection.addRange(range)
      textArea.setSelectionRange(0, 999999)
    } else {
      textArea.select()
    }
  }

  const copyTo = () => {
    document.execCommand("copy")
    document.body.removeChild(textArea)
  }

  createTextArea(textToCopy)
  selectText()
  copyTo()
}
