import React from "react"
import { Link } from "./link"
import styled from "styled-components"

const Wrapper = styled.span`
  text-align: center;
  font-family: Zilla Slab, serif;
  font-size: 19pt;
  font-weight: 500;

  @media only screen and (max-width: 549px) {
    font-size: 17pt;
  }
`

export const Logo = () => {
  return (
    <Wrapper>
      <Link to="/">N. Daily Notes</Link>
    </Wrapper>
  )
}
