import React from "react"
import Button from "@mui/material/Button"
import MenuItem from "@mui/material/MenuItem"
import MenuIcon from "@mui/icons-material/Menu"
import ClickAwayListener from "@mui/material/ClickAwayListener"
import Grow from "@mui/material/Grow"
import Paper from "@mui/material/Paper"
import Popper from "@mui/material/Popper"
import Collapse from "@mui/material/Collapse"
import MenuList from "@mui/material/MenuList"
import * as headerCSS from "../styles/header.module.css"
import { faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "./link"
import styled from "styled-components"

const Wrapper = styled.span`
  @media only screen and (min-width: 951px) {
    display: none;
  }
`

const ButtonText = styled.span`
  display: none;
  @media only screen and (min-width: 549px) {
    display: block;
  }
`

export const Menu = () => {
  const [open, setOpen] = React.useState(false)
  const [open2, setOpen2] = React.useState(false)
  const anchorRef = React.useRef(null)

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }
  const handleToggle2 = () => {
    setOpen2(!open2)
  }

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault()
      setOpen(false)
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }
    prevOpen.current = open
  }, [open])
  return (
    <Wrapper>
      <Button
        id={headerCSS.menu}
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        aria-label="menu"
        onClick={handleToggle}
      >
        <MenuIcon id={headerCSS.burger_menu} />
        <ButtonText>Show menu</ButtonText>
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: 9 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper id={headerCSS.dropDownListContainer}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem onClick={handleToggle2}>
                  愛爾蘭生活故事
                    <span style={{ position: "absolute", right: "15px" }}>
                      <FontAwesomeIcon
                        icon={open2 ? faAngleDown : faAngleRight}
                      />
                    </span>
                  </MenuItem>
                  <Collapse in={open2}>
                    <MenuList autoFocusItem={open2}>
                      <Link to="/category/life">
                        <MenuItem onClick={handleClose}>生活故事書</MenuItem>
                      </Link>
                      <Link to="/category/tips">
                        <MenuItem onClick={handleClose}>辦事打怪篇</MenuItem>
                      </Link>
                      <Link to="/category/jobs">
                        <MenuItem onClick={handleClose}>工作事件簿</MenuItem>
                      </Link>
                      <Link to="/category/living">
                        <MenuItem onClick={handleClose}>租屋私房事</MenuItem>
                      </Link>
                    </MenuList>
                  </Collapse>
                  <Link to="/category/study">
                    <MenuItem onClick={handleClose}>遊學日記</MenuItem>
                  </Link>
                  <Link to="/category/foodie">
                    <MenuItem onClick={handleClose}>美食選輯</MenuItem>
                  </Link>
                  <Link to="/category/travel">
                    <MenuItem onClick={handleClose}>旅行散文</MenuItem>
                  </Link>
                  <Link to="/about-me">
                    <MenuItem onClick={handleClose}>關於我</MenuItem>
                  </Link>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Wrapper>
  )
}
