import { createGlobalStyle } from "styled-components"

export const GlobalStyle = createGlobalStyle`
    a {
        text-decoration: none;
        cursor: pointer;
    }
    
    body {
        margin: 10px 0;
    }
    
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 0;
        padding: 0px;
    }
    li {
        list-style: none;
    }
`
