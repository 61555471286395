import React from "react"
import * as css from "../styles/article.module.css"
import styled from "styled-components"
import { Link } from "./link"
import { GatsbyImage } from "gatsby-plugin-image"
import { Grid } from "@mui/material"
import { getFormatDate } from "../utils/tools"
import { tagMapper } from "../utils/tools"

const Wrapper = styled(Grid)`
  margin-top: "60px";
`

const Image = styled(GatsbyImage)`
  height: 300px;
`

const TabletImage = styled(GatsbyImage)`
  height: 400px;
`

const SmallImage = styled(GatsbyImage)`
  height: 200px;
`

const H2 = styled.h2`
  font-family: Montserrat, Noto Sans TC, sans-serif;
  font-size: 15px;
  color: #000;
  line-height: 1.4;
  font-weight: 400;
`

export const ShortPostMobile = ({ post, image, date }) => {
  const { year, month, day } = getFormatDate(date)
  return (
    <>
      <Wrapper container justify="center" alignItems="center">
        <Grid item xs={12}>
          <Link to={"/articles/" + post.slug} aria-label={post.slug}>
            <Image image={image} alt={post.slug} />
          </Link>
        </Grid>
      </Wrapper>
      <div className={css.container}>
        <h2 className={css.article_title}>
          <Link to={"/articles/" + post.slug}>{post.title}</Link>
        </h2>
        <span className={css.info}>
          <span className={css.catagory}>
            <Link to={"/category/" + post.tag} className="tagHover">
              {tagMapper(post.tag)}
            </Link>
          </span>
          <span className={css.time}>
            <Link to={`/${year}/${month}`}>
              {year}/{month}/{day}
            </Link>
          </span>
        </span>
      </div>
    </>
  )
}

export const ShortPostTablet = ({ post, image, date }) => {
  const { year, month, day } = getFormatDate(date)
  return (
    <>
      <Grid
        container
        justify="center"
        alignItems="center"
        style={{ marginTop: "60px", marginBottom: "30px" }}
      >
        <Grid item xs={12}>
          <Link to={"/articles/" + post.slug} aria-label={post.slug}>
            <TabletImage image={image} alt={post.slug} />
          </Link>
        </Grid>
      </Grid>
      <div style={{ textAlign: "center", marginTop: "40px" }}>
        <span className={css.catagory}>
          <Link to={"/category/" + post.tag} className="tagHover">
            {tagMapper(post.tag)}
          </Link>
        </span>
        <h2 className={css.article_title}>
          <Link to={"/articles/" + post.slug}>{post.title}</Link>
        </h2>
        <span className={css.time}>
          <Link to={`/${year}/${month}`}>
            {year}/{month}/{day}
          </Link>
        </span>
        <div>
          <p className={css.article_content}>{post.excerpt}...</p>
        </div>
      </div>
    </>
  )
}

export const ShortPostWithoutExcerpt = ({ post, image, date }) => {
  const { year, month, day } = getFormatDate(date)
  return (
    <>
      <div style={{ marginTop: "60px", marginBottom: "10px" }}>
        <Link to={"/articles/" + post.slug}>
          <Image image={image} alt={post.slug} />
        </Link>
      </div>
      <div style={{ textAlign: "center", marginTop: "10px" }}>
        <span className={css.catagory}>
          <Link
            to={"/category/" + post.tag}
            className="tagHover"
            aria-label={post.slug}
          >
            {tagMapper(post.tag)}
          </Link>
        </span>
        <h2 className={css.article_title}>
          <Link to={"/articles/" + post.slug}>{post.title}</Link>
        </h2>
        <span className={css.time}>
          <Link to={`/${year}/${month}`}>
            {year}/{month}/{day}
          </Link>
        </span>
      </div>
    </>
  )
}

export const ShortPostDefault = ({ post, image, date }) => {
  const { year, month, day } = getFormatDate(date)
  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      spacing={2}
      style={{ marginTop: "30px", marginBottom: "30px" }}
    >
      <Grid item xs={6}>
        <Link to={"/articles/" + post.slug} aria-label={post.slug}>
          <GatsbyImage image={image} alt={post.slug} />
        </Link>
      </Grid>
      <Grid item xs={6}>
        <div>
          <span className={css.catagory}>
            <Link to={"/category/" + post.tag}>{tagMapper(post.tag)}</Link>
          </span>
          <h2 className={css.article_title}>
            <Link to={"/articles/" + post.slug}>{post.title}</Link>
          </h2>
          <span className={css.time}>
            <Link to={`/${year}/${month}`}>
              {year}/{month}/{day}
            </Link>
          </span>
          <div>
            <p className={css.article_content}>{post.excerpt}...</p>
          </div>
        </div>
      </Grid>
    </Grid>
  )
}

export const ShortPostSuggestion = ({ post, image }) => {
  return (
    <>
      <Wrapper container justify="center" alignItems="center">
        <Grid item xs={12}>
          <Link to={"/articles/" + post.slug} aria-label={post.slug}>
            <SmallImage image={image} alt={post.slug} />
          </Link>
        </Grid>
      </Wrapper>
      <div className={css.container}>
        <H2>
          <Link to={"/articles/" + post.slug}>{post.title}</Link>
        </H2>
      </div>
    </>
  )
}
